$theme: #ff8b00;
$sub: #ffc60b;
$theme-text: #331c00;

$background: #444444;
$text: #dadada;
$secondary-background: #242424;
$secondary-text: #d3d4be;

$optional-gray: #878078;
$optional-gray-text: #f3f2f2;

$complementary: #0074ff;

$mobile-breakpoint: 675px;
$tablet-breakpoint: 1020px;

@mixin button-color($color) {
  background-color: $color;
  transition: background-color 0.3s;
  cursor: pointer;
  box-shadow: 0 2px 2px $secondary-background;

  &:hover {
    background-color: lighten($color, 10%);
  }

  &:focus-visible {
    @include focusable();
    outline-offset: 0px;
    background-color: lighten($color, 10%);
  }
}

@mixin focusable($color: $sub) {
  outline: 2px $color solid;
}
