@import "../variables.scss";

.header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  margin: 1rem 0;
  margin-bottom: 2rem;
  padding: 0 1rem;
  gap: 1rem;

  @media (max-width: $mobile-breakpoint) {
    grid-template-columns: 1fr;
    padding: 0;
  }
}

.titleBox {
  font-size: 1.3rem;

  > p {
    margin: 1em 0;
  }

  @media (max-width: $mobile-breakpoint) {
    font-size: 1rem;
  }
}

.buttonRow {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  > button,
  > a {
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    text-decoration: none;
    line-height: inherit;
    font-weight: 500;

    &.main {
      @include button-color($theme);
      color: $theme-text;
    }
    &.sub {
      @include button-color($sub);
      color: $theme-text;
    }
    &.gray {
      @include button-color($optional-gray);
      color: $optional-gray-text;
    }
  }
}

.statsBox {
  background-color: $secondary-background;
  color: $secondary-text;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin: 0;
  box-shadow: 0 2px 2px black;

  > h2 {
    margin: 0;
  }

  > ul {
    list-style: none;
    padding: 0;
    margin: 0 0.5rem;

    > li {
      display: flex;
      align-items: center;
      gap: 0 0.5rem;
      padding: 0.5rem 0;
      border-bottom: 1px solid #ccc;

      > svg {
        flex-shrink: 0;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.featureSection {
  display: flex;
  background-color: $secondary-background;
  color: $secondary-text;
  border-radius: 5px;
  margin: 1.5rem 0;
  padding: 0.5rem 1rem;
  gap: 1rem;
  flex-wrap: wrap;
  box-shadow: 0 2px 2px black;

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  > .sectionBody {
    flex-basis: calc(50% - 0.5rem);
    flex-grow: 1;
  }

  > .mediaContainer {
    flex-basis: calc(50% - 0.5rem);
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $mobile-breakpoint) {
      min-width: 100%;
    }
  }
}

.videoBox {
  position: relative;
  width: 100%;
  min-width: 344px;
  max-width: 688px;
  aspect-ratio: 75 / 43;
  overflow: hidden;

  @media (max-width: $mobile-breakpoint) {
    min-width: 100%;
  }

  > button {
    width: 100%;
    position: absolute;
    aspect-ratio: 75 / 43;
    border: none;
    cursor: pointer;
    color: inherit;
    padding: 0;
    z-index: 50;

    &:focus-visible {
      @include focusable();
      outline-offset: -2px;
    }
  }

  > .playButton {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 1.2rem;
    font-weight: 600;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  > .pauseButton {
    background-color: transparent;
  }

  > .progressBar {
    position: absolute;
    background-color: $theme;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    transform-origin: left;
    transition: transform 0.1s linear;
    transform: scaleX(0);
  }

  > video {
    width: 100%;
    aspect-ratio: 75 / 43;
  }
}

.featureBoxContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 1000px;
  margin: 1.5rem auto;

  > .featureBox {
    flex-basis: 240px;
    flex-grow: 1;
    flex-shrink: 0;
    background-color: $secondary-background;
    color: $secondary-text;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    box-shadow: 0 2px 2px black;

    > .imageBox {
      width: 100%;
      height: auto;
      max-width: 300px;
      margin: 0 auto;
    }
  }
}

.actionPrompt {
  text-align: center;
  font-size: 1.3rem;

  > .buttonRow {
    justify-content: center;
  }
}
